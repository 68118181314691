<template>
  <v-container id="types" fluid tag="section" class="mt-10">
    <v-row>
      <v-col cols="12">
        <material-card color="primary" full-header>
          <template #heading>
            <div class="pa-8 white--text">
              <div class="text-h4">{{ getHeading }}</div>
            </div>
          </template>

          <div>
            <div
              class="list-config d-flex justify-content-between align-items-baseline"
            >
              <div class="tableConfig d-inline-flex">
                <template v-if="isDevice">
                  <label>Group: </label>
                  <dropdown-list
                    :isListView="true"
                    :object_category="getGroupCategory"
                    :preselected_id="selected_group_id"
                    :type_id_filter="filter_selected_type_id"
                    :disabled="false"
                    @objectIsSelected="groupIsSelected($event)"
                    :isHeaderDropdown="true"
                  />
                </template>

                <!-- Settings Dialog -->
                <div class="text-center">
                  <v-dialog v-model="settings_dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        href="#"
                        v-bind="attrs"
                        v-on="on"
                        @click="$event.preventDefault()"
                      >
                        <v-icon color="blue"> mdi-cog </v-icon>
                      </a>
                    </template>

                    <v-card>
                      <v-card-title> Table configuration</v-card-title>

                      <div>
                        <ul>
                          <li
                            v-for="(object, key) in headers_object"
                            :key="key"
                          >
                            <input
                              :disabled="object.key == 'name'"
                              type="checkbox"
                              v-model="object.checked"
                              @change="columnVisibilityToggleEvent(object)"
                            />
                            {{ object.text }}
                          </li>
                        </ul>
                      </div>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <base-button
                          @click="saveUserPreferences"
                          text="Save preferences"
                          :is_accent="true"
                        />
                        <base-button
                          @click="settings_dialog = false"
                          text="Close"
                        />
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>

                <!-- Reload data !-->
                <div class="text-center">
                  <a href="#" @click="reloadData($event)">
                    <v-icon color="blue"> mdi-refresh </v-icon>
                  </a>
                </div>

                <!-- Export data dialog -->
                <export-data />
              </div>

              <v-spacer />

              <div class="d-inline-flex">
                <base-button
                  v-if="!isDocument && !isTeam"
                  :is_accent="true"
                  text="Add new"
                  :to="getNewUrl"
                />

                <v-toolbar
                  class="short-toolbar pt-1"
                  elevation="0"
                  hide-details
                  dense
                >
                  <v-text-field
                    prepend-icon="mdi-magnify"
                    v-model="search"
                  ></v-text-field>
                </v-toolbar>
              </div>
            </div>

            <div class="ml-7" v-if="isDocument">
              <add-document-buttons />
            </div>

            <v-switch
              class="ml-7"
              hide-details
              v-if="isFirmware"
              v-model="show_disabled"
              label="Show disabled firmwares"
            />

            <base-button
              class="mx-10 mt-5"
              v-if="isTeam"
              :is_accent="true"
              text="Team management"
              target="_blanc"
              href="https://users.strataggem.com/"
            />

            <v-data-table
              :dense="false"
              :headers="headers_for_table"
              :items="filtredObjectsList"
              :calculate-widths="true"
              class="elevation-0 ma-5 limit-width-10 custom-v-table"
              :item-class="itemRowBackground"
              :multi-sort="true"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template v-slot:item.name="{ item }">
                <router-link
                  v-if="!isTeam"
                  :to="getViewUrl(item.id, item.is_document_collection)"
                >
                  {{ item.name }}
                </router-link>
                <span v-else>
                  <a href="https://users.strataggem.com/" target="_blanc">
                    {{ item.name }}
                  </a>
                </span>
                <v-tooltip
                  bottom
                  v-if="
                    isFirmware &&
                    ((null != item.is_target_of &&
                      item.is_target_of.length > 0) ||
                      (null != item.is_currently_on &&
                        item.is_currently_on.length > 0))
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="pl-1">
                      mdi-devices
                    </v-icon>
                  </template>
                  <span>Used by devices</span>
                </v-tooltip>
              </template>

              <template v-slot:item.uid_for_type="{ item }">
                {{ item.uid_for_type }}
                <span
                  v-if="
                    null != item.type &&
                    null != item.type.name_of_uid &&
                    '' != item.type.name_of_uid
                  "
                  class="ma-0 text--secondary"
                >
                  ({{ item.type.name_of_uid }})</span
                >
              </template>

              <template v-slot:item.current_firmware="{ item }">
                <span>
                  <template v-if="null != item.current_firmware">
                    <router-link
                      :to="getFirmwareViewUrl(item.current_firmware.id)"
                    >
                      {{ item.current_firmware.name }}
                    </router-link>
                    ({{ item.current_firmware.version }})
                  </template>
                  <actual-firmware-icon
                    :target_firmware_id="item.target_firmware_id"
                    :current_firmware_id="item.current_firmware_id"
                  />
                </span>
              </template>

              <template v-slot:item.target_firmware="{ item }">
                <v-icon v-if="null == item.target_firmware" color="alert">
                  mdi-book-off-outline
                </v-icon>
                <span v-else>
                  <router-link
                    :to="getFirmwareViewUrl(item.target_firmware.id)"
                  >
                    {{ item.target_firmware.name }}
                  </router-link>
                  ({{ item.target_firmware.version }})
                </span>
              </template>

              <template v-slot:item.type="{ item }">
                <span v-if="item.type == null" class="font-italic">
                  Type is undefined
                </span>
                <span v-else>
                  <router-link :to="getTypeViewUrl(item.type.id)">
                    {{ item.type.name }}
                  </router-link>
                </span>
              </template>

              <template v-slot:item.group="{ item }">
                <span v-if="item.group == null" class="font-italic">-</span>
                <span v-else>
                  <router-link :to="getGroupViewUrl(item.group.id)">{{
                    item.group.name
                  }}</router-link>
                </span>
              </template>

              <template v-slot:item.date="{ item }">
                {{ formatDate(item.date) }}
              </template>

              <template v-slot:item.last_seen="{ item }">
                {{ formatDate(item.last_seen, true) }}
              </template>

              <template v-slot:item.filename="{ item }">
                <a
                  v-if="null != item.filename"
                  href="#"
                  @click="downloadFirmwareFile(item.id, $event)"
                  >{{ item.filename }}</a
                >

                <v-icon v-else color="alert"> mdi-file-alert-outline </v-icon>
              </template>

              <template v-slot:item.size="{ item }">
                <span v-if="null != item.size && 0 != item.size.length">
                  {{ item.size }}</span
                >
                <v-icon v-else color="alert"> mdi-file-alert-outline </v-icon>
              </template>

              <template v-slot:item.icon="{ item }">
                <icon-image :image_src="item.icon" :small_icon="true" />
              </template>

              <template v-slot:item.author="{ item }">
                <span
                  v-if="
                    null != item.author && item.author.username == user.username
                  "
                  class="you"
                  >you</span
                >
                <span v-else>
                  {{ null == item.author ? "" : item.author.username }}
                </span>
              </template>

              <template v-slot:item.role="{ item }">
                {{ getRoleName(item) }}
              </template>

              <template v-slot:item.team="{ item }">
                {{ null == item.team ? "" : item.team.name }}
              </template>

              <template v-slot:item.actions="{ item }">
                <div :style="canUserEdit(item) ? 'min-width: 7em;' : null">
                  <span class="pr-3">
                    <router-link
                      :to="getViewUrl(item.id, item.is_document_collection)"
                    >
                      <v-icon color="blue"> mdi-magnify </v-icon>
                    </router-link>
                  </span>
                  <span class="pr-3" v-if="canUserEdit(item) && !isTeam">
                    <router-link
                      :to="getEditUrl(item.id, item.is_document_collection)"
                    >
                      <v-icon color="blue"> mdi-pencil </v-icon>
                    </router-link>
                  </span>
                  <span v-if="canUserEdit(item) && !isTeam && !isDocument">
                    <a
                      href="#"
                      @click="
                        deleteObject(
                          $event,
                          item.id,
                          item.name,
                          item.is_document_collection
                        )
                      "
                    >
                      <v-icon color="blue"> mdi-delete </v-icon>
                    </a>
                  </span>
                </div>
              </template>
            </v-data-table>
          </div>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
import { formatDate } from "../utils/DateUtils";
import { getNameOfUserRoleInTeam } from "../utils/RolesUtils";

import {
  getViewUrl,
  getEditUrl,
  getNewUrl,
  getCategoryLabel,
  isDevice,
  isGroup,
  isFirmware,
  isTeam,
  getTypeCategory,
  getGroupCategory,
  getFirmwareCategory,
  getDeviceCategory,
  OBJECT_CATEGORIES,
  isDocument,
} from "../utils/RoutesUtils";
import {
  deleteObject,
  editUserPreferences,
  getObjectsList,
  downloadFirmwareFile,
  getTeamsListWhereUserIsAdminCall,
} from "../utils/ServerUtils.js";
import ActualFirmwareIcon from "../layouts/default/widgets/ActualFirmwareIcon.vue";
import BaseButton from "../components/app/BaseButton.vue";
import DropdownList from "../layouts/default/widgets/DropdownList.vue";
import IconImage from "../components/app/IconImage.vue";
import { mapGetters, mapActions } from "vuex";
import ExportData from "../layouts/default/widgets/ExportData.vue";
import AddDocumentButtons from "../layouts/default/widgets/AddDocumentButtons.vue";

var cmp = require("semver-compare");

function convertAllTableHeadersToObjectSpecific(headers_all_objects) {
  var headers_object = [];

  // Object.entries returns both the key [0] and property [1] values.
  const entries = Object.entries(headers_all_objects);

  for (let i = 0; i < entries.length; i++) {
    const element = entries[i];
    if (element[1].exist) {
      headers_object.push({
        key: element[0],
        text: element[1].title,
        checked: element[1].checked,
      });
    }
  }
  return headers_object;
}

function isSortableColumn(element) {
  return (
    element.key == "name" ||
    element.key == "id" ||
    element.key == "uid_for_type" ||
    element.key == "date" ||
    element.key == "last_seen" ||
    element.key == "version" ||
    element.key == "doc_type"
  );
}

function getSortFunctionIfAny(element) {
  try {
    switch (element.key) {
      case "date":
      case "last_seen":
        return function (a, b) {
          const a_as_time = new Date(a).getTime();
          const b_as_time = new Date(b).getTime();
          if (a_as_time < b_as_time) return -1;
          if (a_as_time > b_as_time) return 1;
          return 0;
        };
      case "version":
        return function (a, b) {
          return cmp(a, b);
        };

      default:
        return null;
    }
  } catch (error) {
    return null;
  }
}

export default {
  name: "ObjectsTable",

  components: {
    DropdownList,
    IconImage,
    ActualFirmwareIcon,
    BaseButton,
    ExportData,
    AddDocumentButtons,
  },

  data() {
    return {
      objects_list: [],

      selected_group_id: null,
      headers_all_objects: [], // holds table headers for all object category (device, firmware, type or group)
      headers_object: [], // headers_all_objects filtred by a specific object category (ex. a device does not have a "version" field)
      data_is_loaded: false,
      teams_where_user_is_admin: [],

      search: "",

      settings_dialog: false,
      export_dialog: false,

      is_table_overflow: false,

      show_disabled: false,

      sortBy: null,
      sortDesc: null,
    };
  },

  watch: {
    search: function () {
      // trigger filtredObjectsList change
      const objects_list = this.objects_list;
      this.objects_list = objects_list;
    },
    user: function () {
      this.updateColumnsBasedOnUserPreferences();
      this.headers_object = convertAllTableHeadersToObjectSpecific(
        this.headers_all_objects
      );
    },

    filter_selected_type_id: function (old_value, new_value) {
      if (old_value != new_value) {
        this.groupIsSelected(null);
      }

      this.toogleColumnsBasedOnFilter();
      this.updateTableSort();
    },
  },

  created() {
    this.updateTableHeaders();

    window.addEventListener("resize", this.windowResizeHandler);
  },

  mounted: async function () {
    this.loadData();
    this.getTeamsWhereUserIsAdmin();

    this.selected_group_id = this.filter_selected_group_id;
    this.updateTableSort();
  },

  destroyed() {
    window.removeEventListener("resize", this.windowResizeHandler);
  },

  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["object_category"]),
    ...mapGetters(["filter_selected_type_id"]),
    ...mapGetters(["filter_selected_group_id"]),
    ...mapGetters(["filter_selected_team_id"]),

    headers_for_table() {
      var headers_converted = [];

      for (let i = 0; i < this.headers_object.length; i++) {
        const element = this.headers_object[i];

        if (element.checked) {
          headers_converted.push({
            value: element.key,
            text: element.text,
            sortable: isSortableColumn(element) ? true : false,
            sort: getSortFunctionIfAny(element),
          });
        }
      }

      return headers_converted;
    },

    filtredObjectsList: function () {
      var filtred_objects_list = [];
      for (let i = 0; i < this.objects_list.length; i++) {
        const object_i = this.objects_list[i];
        if (
          !this.isFilteredByTeam(object_i) &&
          !this.isFilteredByType(object_i) &&
          !this.isFilteredByGroup(object_i) &&
          !this.isFilteredBySearch(object_i)
        ) {
          filtred_objects_list.push(object_i);
        }
      }
      return filtred_objects_list;
    },

    getHeading: function () {
      const wait_server_indicator = this.data_is_loaded
        ? ""
        : " [waiting for server response]";

      const objects_num_string =
        (null == this.filter_selected_team_id &&
          null == this.filter_selected_type_id) ||
        this.isTeam
          ? this.objects_list.length
          : this.filtredObjectsList.length + "/" + this.objects_list.length;
      return (
        getCategoryLabel(this.object_category, true) +
        " (" +
        objects_num_string +
        ")" +
        wait_server_indicator
      );
    },

    hasTargetFirmware: function () {
      return isDevice(this.object_category) || isGroup(this.object_category);
    },

    hasType: function () {
      return (
        isDevice(this.object_category) ||
        isGroup(this.object_category) ||
        isFirmware(this.object_category)
      );
    },

    hasDate: function () {
      return isDevice(this.object_category) || isFirmware(this.object_category);
    },

    isDevice: function () {
      return isDevice(this.object_category);
    },

    isFirmware: function () {
      return isFirmware(this.object_category);
    },

    isTeam: function () {
      return isTeam(this.object_category);
    },

    isDocument: function () {
      return isDocument(this.object_category);
    },

    getNewUrl: function () {
      return getNewUrl(this.object_category);
    },

    getTypeCategory() {
      return getTypeCategory();
    },

    getGroupCategory() {
      return getGroupCategory();
    },
  },

  methods: {
    ...mapActions(["updateSelectedTypeId"]),
    ...mapActions(["updateSelectedGroupId"]),
    ...mapActions(["updateUserPreferences"]),
    ...mapActions(["updateLastUpdateTime"]),

    itemRowBackground: function (item) {
      if (item.is_disabled && this.show_disabled) {
        return "light_red";
      }
      if (item.is_disabled && !this.show_disabled) {
        return "d-none";
      }

      return null;
    },

    windowResizeHandler: function () {
      try {
        const scroll = this.$refs["table_scroll"].$el;
        const table = this.$refs["table_objects"];

        if (scroll.clientWidth < table.clientWidth) {
          this.is_table_overflow = true;
        } else {
          this.is_table_overflow = false;
        }
      } catch {
        // do nothing
      }
    },

    loadData: async function () {
      const objects_list = await getObjectsList(this.object_category);
      if (null != objects_list) {
        if (this.isDocument) {
          // We use the page with basic documents to also display the documents collections.
          // So we need to:
          // 1. Mark basic documents as such
          // 2. Get the documents collections, mark them as such and add them to the list
          for (let i = 0; i < objects_list.length; i++) {
            const doc = objects_list[i];
            // We should indicate that the document is not just a basic document, but a collection of documents.
            doc.is_basic_document = true;
            objects_list[i] = doc;
          }

          // TODO: for the moment, the collections (groups) of documets are hidden from the user.
          // The collections will still be created when the docs are added to objects,
          // but the user will not be aware of this.

          // const docs_collection = await getObjectsList(
          //   OBJECT_CATEGORIES.DOCUMENTS_COLLECTION
          // );

          // for (let i = 0; i < docs_collection.length; i++) {
          //   const doc = docs_collection[i];
          //   // We should indicate that the document is not just a basic document, but a collection of documents.
          //   doc.is_document_collection = true;
          //   objects_list.push(doc);
          // }
        }

        this.objects_list = objects_list;
      }

      this.data_is_loaded = true;

      this.updateLastUpdateTime();
    },

    async getTeamsWhereUserIsAdmin() {
      const objects_list = await getTeamsListWhereUserIsAdminCall();
      if (null != objects_list) this.teams_where_user_is_admin = objects_list;
    },

    reloadData: function (event) {
      event.preventDefault();
      this.loadData();
    },

    groupIsSelected: function (object) {
      if (null != object) {
        this.selected_group_id = object.id;
        this.updateSelectedGroupId(this.selected_group_id);
      } else {
        this.selected_group_id = null;
        this.updateSelectedGroupId(null);
      }

      this.toogleColumnsBasedOnFilter();
    },

    toogleColumnsBasedOnFilter: function () {
      this.toogleColumn("type", this.filter_selected_type_id);
      this.toogleColumn("group", this.selected_group_id);
    },

    updateTableSort() {
      // Sort firmwares by name and version if the type is selected
      if (this.filter_selected_type_id != null && this.isFirmware) {
        this.sortBy = ["name", "version"];
      } else {
        this.sortBy = null;
      }
    },

    toogleColumn: function (key, filter_value) {
      var col = this.getColumn(key);
      if (null != col) {
        col.checked = null == filter_value;
        this.columnVisibilityToggleEvent(col);
      }
    },

    getColumn: function (key) {
      for (let i = 0; i < this.headers_object.length; i++) {
        const col = this.headers_object[i];
        if (key == col.key) return col;
      }
      return null;
    },

    formatDate: function (
      object_date,
      show_time_flag = false,
      simple_format = false
    ) {
      return formatDate(object_date, show_time_flag, simple_format);
    },

    getViewUrl: function (object_id, is_document_collection = false) {
      var object_category = this.object_category;
      if (this.isDocument) {
        if (true == is_document_collection) {
          object_category = OBJECT_CATEGORIES.DOCUMENTS_COLLECTION;
        } else {
          object_category = OBJECT_CATEGORIES.BASIC_DOCUMENT;
        }
      }

      return getViewUrl(object_id, object_category);
    },

    getFirmwareViewUrl: function (object_id) {
      return getViewUrl(object_id, getFirmwareCategory());
    },

    getGroupViewUrl: function (object_id) {
      return getViewUrl(object_id, getGroupCategory());
    },

    getTypeViewUrl: function (object_id) {
      return getViewUrl(object_id, getTypeCategory());
    },

    getDeviceViewUrl: function (object_id) {
      return getViewUrl(object_id, getDeviceCategory());
    },

    getEditUrl: function (object_id, is_document_collection = false) {
      var object_category = this.object_category;
      if (this.isDocument) {
        if (true == is_document_collection) {
          object_category = OBJECT_CATEGORIES.DOCUMENTS_COLLECTION;
        } else {
          object_category = OBJECT_CATEGORIES.BASIC_DOCUMENT;
        }
      }
      return getEditUrl(object_id, object_category);
    },

    getRoleName(team_object) {
      if (this.user) return getNameOfUserRoleInTeam(team_object, this.user.id);
    },

    canUserEdit(object) {
      for (const admin_team of this.teams_where_user_is_admin) {
        if (admin_team.id == object.team_id) return true;
      }
      return false;
    },

    deleteObject: async function (
      e,
      object_id,
      object_name,
      is_document_collection = false
    ) {
      e.preventDefault();

      var object_category = this.object_category;

      // TODO: the deletion of documents is temporary disabled.
      // The reason: we should first make sure to delete its parent collection if
      // it is not used in any
      if (this.isDocument) {
        alert("Sorry, the deletion of documents is temporarily disabled.");

        // if (true == is_document_collection) {
        //   object_category = OBJECT_CATEGORIES.DOCUMENTS_COLLECTION;
        // } else {
        //   object_category = OBJECT_CATEGORIES.BASIC_DOCUMENT;
        // }
      } else {
        await deleteObject(object_id, object_category, object_name);
        this.loadData();
      }
    },

    updateTableHeaders: function () {
      this.headers_all_objects = this.getDefaultColumns();
      this.updateColumnsBasedOnUserPreferences();
      this.headers_object = convertAllTableHeadersToObjectSpecific(
        this.headers_all_objects
      );
    },

    updateColumnsBasedOnUserPreferences: function () {
      if (null != this.user) {
        try {
          var user_headers = JSON.parse(this.user.preferences);
          for (var column in user_headers) {
            this.headers_all_objects[column].checked =
              user_headers[column].checked;
          }
        } catch (e) {
          // do nothing
        }
      }
    },

    renderColumn: function (key) {
      const field_exist_for_object = this.headers_all_objects[key].exist;
      const not_hidden = this.headers_all_objects[key].checked;
      return field_exist_for_object && not_hidden;
    },

    displayNoneIfHiden: function (key) {
      const is_hidden = false == this.headers_all_objects[key].checked;
      return is_hidden ? "display-none" : null;
    },

    columnVisibilityToggleEvent(object) {
      this.headers_all_objects[object.key].checked = object.checked;
    },

    isFilteredByTeam(object) {
      if (this.isTeam) return false;

      return (
        null != this.filter_selected_team_id &&
        object.team_id != this.filter_selected_team_id
      );
    },

    isFilteredByType(object) {
      return (
        this.hasType &&
        null != this.filter_selected_type_id &&
        object.type_id != this.filter_selected_type_id
      );
    },

    isFilteredByGroup(object) {
      return (
        this.isDevice &&
        null != this.selected_group_id &&
        object.group_id != this.selected_group_id
      );
    },

    isFilteredBySearch(object) {
      return (
        this.search.length > 0 &&
        !object.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },

    saveUserPreferences: async function () {
      const preferences = JSON.stringify(this.getColumnsDifferentFromDefault());
      await editUserPreferences(this.user.id, preferences);

      this.updateUserPreferences(preferences);
      this.settings_dialog = false;
    },

    getColumnsDifferentFromDefault: function () {
      var defaultVisibility = this.getDefaultColumns();
      var differentVisibility = {};
      for (const column in this.headers_all_objects) {
        if (
          this.headers_all_objects[column].checked !=
          defaultVisibility[column].checked
        ) {
          differentVisibility[column] = {
            checked: this.headers_all_objects[column].checked,
          };
        }
      }
      return differentVisibility;
    },

    getDefaultColumns: function () {
      return {
        id: { title: "PUCS ID", exist: true, checked: false },
        name: { title: "Name", exist: true, checked: true },
        doc_type: {
          title: "Document type",
          exist: this.isDocument,
          checked: true,
        },
        uid_for_type: {
          title: "Unique ID by type",
          exist: this.isDevice,
          checked: true,
        },
        current_firmware: {
          title: "Current firmware",
          exist: this.isDevice,
          checked: true,
        },
        target_firmware: {
          title: "Target firmware",
          exist: this.hasTargetFirmware,
          checked: true,
        },
        version: { title: "Version", exist: this.isFirmware, checked: true },
        type: { title: "Type", exist: this.hasType, checked: true },
        group: { title: "Group", exist: this.isDevice, checked: true },
        date: { title: "Creation date", exist: this.hasDate, checked: true },
        last_seen: { title: "Last seen", exist: this.isDevice, checked: true },
        filename: { title: "File name", exist: this.isFirmware, checked: true },
        size: { title: "Size", exist: this.isFirmware, checked: true },
        icon: {
          title: "Icon",
          exist: !this.isTeam && !this.isDocument,
          checked: true,
        },
        author: {
          title: "Owner",
          exist: !this.isTeam,
          checked: true,
        },
        role: {
          title: "Role",
          exist: this.isTeam,
          checked: true,
        },
        team: {
          title: "Team",
          exist: !this.isTeam,
          checked: true,
        },
        actions: { title: "Actions", exist: !this.isTeam, checked: true },
      };
    },

    downloadFirmwareFile: function (id, e) {
      e.preventDefault();
      downloadFirmwareFile(id);
    },
  },
};
</script>

<style scoped>
.modal-content {
  padding: 1em;
}

.display-none {
  display: none;
}

.you {
  margin: 0;
  font-style: italic;
}

.v-input__slot {
  margin-bottom: 0;
}
</style>
