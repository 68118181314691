<template>
  <div>
    <v-row class="mr-5">
      <v-col
        cols="12"
        sm="6"
        lg="3"
        v-for="(object_type, i) in documentable_objects"
        :key="i"
      >
        <div class="sub-container">
          <p>Add documentation for {{ object_type }}</p>
          <base-button
            v-for="(doc_type, j) in doc_types"
            :key="j"
            :is_accent="true"
            :text="'New ' + doc_type"
            class="mr-2 mb-2"
            @click="addBtnClicked(object_type, doc_type)"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getNewUrl, OBJECT_CATEGORIES } from "../../../utils/RoutesUtils";

export default {
  data() {
    return {
      object_category: null,
      target_class: null,
    };
  },

  created() {
    this.object_category = OBJECT_CATEGORIES.DOCUMENT;
  },

  computed: {
    documentable_objects() {
      return ["type", "firmware", "group", "device"];
    },

    doc_types() {
      return ["file", "image", "link", "text"];
    },
  },

  methods: {
    addBtnClicked(target_class, doc_type) {
      var new_doc_url = getNewUrl(this.object_category);
      new_doc_url =
        new_doc_url +
        "?target_class=" +
        target_class +
        "&" +
        "doc_type=" +
        doc_type;

      this.$router.push(new_doc_url);
    },
  },
};
</script>
