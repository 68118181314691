<template>
  <div class="text-center">
    <v-dialog v-model="export_dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <a href="#" v-bind="attrs" v-on="on" @click="$event.preventDefault()">
          <v-icon color="blue"> mdi-download </v-icon>
        </a>
      </template>

      <v-card>
        <v-card-title>Export options</v-card-title>

        <v-divider></v-divider>

        <div class="px-2">
          <div class="pa-2">
            <label>Which team's devices should be exported?</label>

            <br />

            <dropdown-list
              ref="teams_dropdown"
              :object_category="getTeamCategory"
              @objectIsSelected="dropdownSelected($event, 'team_id')"
              :required="true"
              @objectsListLoaded="teams_list_loaded = true"
            />
          </div>

          <div
            class="pa-2"
            v-if="teams_list_loaded && selected_team_id != null"
          >
            <label>Do you want to restrict by a specific type?</label>

            <br />

            <dropdown-list
              :object_category="getTypeCategory"
              :team_id_filter="selected_team_id"
              @objectIsSelected="dropdownSelected($event, 'type_id')"
              @objectsListLoaded="types_list_loaded = true"
            />
          </div>

          <div
            class="pa-2"
            v-if="types_list_loaded && selected_type_id != null"
          >
            <label>Do you want to restrict by a specific group?</label>

            <br />

            <dropdown-list
              :object_category="getGroupCategory"
              :type_id_filter="selected_type_id"
              @objectIsSelected="dropdownSelected($event, 'group_id')"
              @objectsListLoaded="groups_list_loaded = true"
            />
          </div>
        </div>

        <v-card-actions>
          <p class="red--text">
            {{ error_message }}
          </p>

          <v-spacer></v-spacer>

          <base-button
            @click="exportData"
            text="Export devices data"
            :is_accent="true"
          />
          <base-button @click="export_dialog = false" text="Close" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { OBJECT_CATEGORIES } from "../../../utils/RoutesUtils";
import { DownloadDevicesAsCsv } from "../../../utils/ServerUtils";
import DropdownList from "./DropdownList.vue";
export default {
  components: { DropdownList },
  name: "ExportData",

  data() {
    return {
      export_dialog: false, // export dialog visibility
      teams_list_loaded: false, // flag for loading teams list from server
      types_list_loaded: false, // flag for loading types list from server
      groups_list_loaded: false, // flag for loading groups list from server

      selected_team_id: null,
      selected_type_id: null,
      selected_group_id: null,

      error_message: null, // error message
    };
  },

  computed: {
    getTeamCategory() {
      return OBJECT_CATEGORIES.TEAM;
    },

    getTypeCategory() {
      return OBJECT_CATEGORIES.TYPE;
    },

    getGroupCategory() {
      return OBJECT_CATEGORIES.GROUP;
    },
  },

  methods: {
    exportData: async function (event) {
      event.preventDefault();

      if (this.validateForm()) {
        await DownloadDevicesAsCsv(
          this.selected_team_id,
          this.selected_type_id,
          this.selected_group_id
        );
      }
    },

    validateForm() {
      if (null != this.$refs["teams_dropdown"]) {
        this.$refs["teams_dropdown"].validateInput();
      }

      if (null == this.selected_team_id) {
        this.error_message = "Please, select a team.";
        return false;
      } else {
        this.error_message = null;
        return true;
      }
    },

    dropdownSelected: function (object, field) {
      if ("team_id" === field) {
        if (object != null && object.id != null) {
          this.selected_team_id = object.id;
        } else {
          this.selected_team_id = null;
          this.selected_type_id = null;
          this.selected_group_id = null;
        }
      } else if ("type_id" === field) {
        if (object != null && object.id != null) {
          this.selected_type_id = object.id;
        } else {
          this.selected_type_id = null;
          this.selected_group_id = null;
        }
      } else if ("group_id" === field) {
        if (object != null && object.id != null) {
          this.selected_group_id = object.id;
        } else {
          this.selected_group_id = null;
        }
      }

      if (null != object) {
        this.validateForm();
      }
    },
  },
};
</script>

<style></style>
